import React from "react";
import "./Landing.scss";

const Landing = () => (
  <div className="landing">
    <h2 className="landing__text">
      {/* I'm Kay,
      <br /> */}
      JavaScript enthusiast based in Canada
    </h2>
    <p className="landing__details">
      <p>
      With the mind of an engineer and the eye of a designer, I contribute at start-up companies on front-end application development using the Angular framework and a product mindset.
      </p>

      <p>I take pride in being a self-starter, quick learner and team player who loves working in collaborative environments with an open mind.</p>
    </p>
    <br />
    <button className="btn__cta">
      <a
        href="https://drive.google.com/file/d/1BM3bwD0DTd21e12be2dl4QhieR_vJdOO/view"
        target="_blank"
        rel="noopener noreferrer"
      >
        View my resume
      </a>
    </button>
  </div>
);

export default Landing;
