import React from "react";
import "./Skills.scss";

const MAIN_SKILLS = [
  {
    id: 0,
    name: "Front-end & UI",
    details: "TypeScript, Angular/React, HTML/CSS, TailwindCSS, NgRx",
  },
  // {
  //   id: 1,
  //   name: "UI development",
  //   details: "SCSS, Tailwind, Material-UI, styled-components, etc",
  // },
  {
    id: 1,
    name: "Back-end",
    details: "Python, Node.js, PostgreSQL, RESTful APIs",
  },
  {
    id: 2,
    name: "Soft skills",
    details: "Accountability, Prioritization, Communication",
  },
];

const VALUES = [
  {
    id: 0,
    name: "Set short-term, consistent goals",
    details: "Because failing to plan means planning to fail.",
  },
  {
    id: 1,
    name: "Be collaborative",
    details: "Because team work is key.",
  },
  {
    id: 2,
    name: "Always learning",
    details: "Because why not?",
  },
];

const Skills = () => (
  <div className="skills">
    <div className="skills__section">
      <p className="skills__section--title">Competencies</p>
      <ul className="skills__section--list">
        {MAIN_SKILLS.map((skill) => (
          <li key={skill.id}>
            {skill.name}
            <br />
            <p>{skill.details}</p>
          </li>
        ))}
      </ul>
    </div>

    <div className="skills__section">
      <p className="skills__section--title">Values</p>
      <ul className="skills__section--list">
        {VALUES.map((value) => (
          <li key={value.id}>
            {value.name}
            {/* <br /> */}
            <p>{value.details}</p>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Skills;
