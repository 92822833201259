import React from "react";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import "./Footer.scss";

const Footer = () => (
  <div className="footer">
    <a
      href="https://github.com/k-awe-some"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaGithub />
    </a>
    <a
      href="https://www.linkedin.com/in/kaynguyen-dev/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaLinkedinIn />
    </a>
    <a
      href="mailto:kaynguyen.dev@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MdEmail />
    </a>
  </div>
);

export default Footer;
