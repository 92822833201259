import React from "react";
import { AiOutlineRead } from "react-icons/ai";
import { MdComputer } from "react-icons/md";

import "./ReadingList.scss";

const BOOKS = [
  {
    id: 0,
    name: "Mastering JavaScript Functional Programming",
    author: "Federico Kereki",
    url: "https://www.packtpub.com/web-development/mastering-javascript-functional-programming",
  },
  {
    id: 1,
    name: "Think Like a Programmer",
    author: "V. Anton Spraul",
    url: "https://nostarch.com/thinklikeaprogrammer",
  },
  { id: 2, name: "D3.js" },
  // { id: 3, name: "Python and Machine Learning" }
];

const ReadingList = () => (
  <div className="books">
    <div className="books__section">
      <p className="books__section--title">Readings</p>
      <ul className="books__section--list">
        {BOOKS.map((book) => (
          <li key={book.id}>
            {book.author ? <AiOutlineRead /> : <MdComputer />}
            <a href={book.url} target="_blank" rel="noopener noreferrer">
              <span id="book-name">{book.name}</span>{" "}
              {book.author ? (
                <span id="book-author">by {book.author}</span>
              ) : null}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default ReadingList;
