import React, { useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavHashLink as NavLink } from "react-router-hash-link";
import "./BurgerMenu.scss";

const BurgerMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowMenu(false);
  };

  const toggleMenu = () =>
    showMenu === false ? setShowMenu(true) : setShowMenu(false);

  return (
    <span className="menu" ref={node}>
      {showMenu === false ? (
        <GiHamburgerMenu
          className="menu__icon"
          onClick={toggleMenu}
          size={35}
        />
      ) : (
        <ul className="menu__panel">
          <NavLink
            className="menu__panel--item"
            smooth
            to="#home"
            onClick={toggleMenu}
          >
            home
          </NavLink>
          {/* <NavLink
            className="menu__panel--item"
            smooth
            to="#projects"
            onClick={toggleMenu}
          >
            projects
          </NavLink> */}
          <NavLink
            className="menu__panel--item"
            smooth
            to="#about"
            onClick={toggleMenu}
          >
            about me
          </NavLink>

          <NavLink
            className="menu__panel--item"
            smooth
            to="#contact"
            onClick={toggleMenu}
          >
            contact
          </NavLink>
        </ul>
      )}
    </span>
  );
};
export default BurgerMenu;
