import React from "react";
import profileImg from "../../assets/profileImg1.jpg";
import "./About.scss";
import ReadingList from "./ReadingList/ReadingList";
import Skills from "./Skills/Skills";


const About = () => (
  <div className="about">
    <div className="about__details">
      <img src={profileImg} alt="Kay Nguyen" />
      <p>
        I develop mainly in <i>Angular</i>, <i>TypeScript</i> and <i>NgRx</i>.
        When not coding, my other responsibilities include participating and providing feedback in brainstorming sessions, communicating with stakeholders on project updates, and taking full ownership of deployments and incident responses.

        <br />
        <br />
        
        These days I'm fascinated by the beauty of data visualization and
        have been exploring{" "}
        <a href="https://d3js.org/" target="_blank" rel="noopener noreferrer">
          D3.js
        </a>.        
      </p>
    </div>

    <Skills />
    <ReadingList />
  </div>
);

export default About;
