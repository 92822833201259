import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import logo from "./logo.svg";
import "./App.scss";

import About from "./components/About/About";
import BurgerMenu from "./components/BurgerMenu/BurgerMenu";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Landing from "./components/Landing/Landing";
import NavBar from "./components/NavBar/NavBar";

function App() {
  return (
    <Router>
      <div className="app" id="home">
        <div className="app__intro">
          <BurgerMenu />
          <NavBar />
        </div>
        <Landing />

        <h1 id="about">Profile</h1>
        <About />

        {/* <h1 id="projects">My projects.</h1>
        <Projects /> */}

        <h1 id="contact">Contact</h1>
        <Contact />

        <Footer />
      </div>
    </Router>
  );
}

export default App;
