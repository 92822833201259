import React from "react";
import "./Contact.scss";

const Contact = () => (
  <div className="contact">
    <p className="contact__email">
      Looking to collaborate? Shoot me an email and I'll be
      thrilled to connect!
    </p>
    <button className="btn__cta">
      <a href="mailto:kaynguyen.dev@gmail.com">Email me</a>
    </button>
  </div>
);

export default Contact;
