import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import "./NavBar.scss";

const NavBar = () => (
  <div className="navbar">
    <NavLink smooth to="#home" className="navbar__home">
      Kay.
    </NavLink>
    <ul className="navbar__menu">
      <li>
        <NavLink
          exact
          activeClassName="navbar__menu--active"
          smooth
          to="#about"
        >
          about
        </NavLink>
      </li>

      {/* <li>
        <NavLink
          exact
          activeClassName="navbar__menu--active"
          smooth
          to="#projects"
        >
          projects
        </NavLink>
      </li> */}

      <li>
        <NavLink
          exact
          activeClassName="navbar__menu--active"
          smooth
          to="#contact"
        >
          contact
        </NavLink>
      </li>
    </ul>
  </div>
);

export default NavBar;
